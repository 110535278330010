<template>
  <div>
    <PageLayout>
      <b-row no-gutters class="w-75 mx-auto">
      <b-col class=" bg-black pb-3">
        <div class="my-5 text-white text-center">
          <span
            ><b class="h4">{{userName}}</b> 님의 등록 완료된 수업정보입니다.</span
          >
        </div>
            <div class="text-right pr-5 py-2" v-if="!disablePaymentConfig">
              <b-button v-if="options" class="coursecancel-wrapper my-1 ml-auto" @click="openCancelPayment">
              <span>수강취소</span>
              <b-icon icon="arrow-right"></b-icon>
            </b-button>
            </div>
        <div class="mx-lg-5 mx-md-3 content pt-5 position-relative">
          <b-row no-gutters class="px-5 mt-5">
            <b-col lg="4" xl="4" md="12" sm="12" xs="12">
              <label
              v-for="(option, index) in options"
              :key="index"
                class="w-75 mx-auto my-4 d-block position-relative cursor_pointer"
              >
                <div
                  :class="[
                    'w-100 px-2 py-2 custom-check-box d-flex justifi-content-start align-items-center',
                    {
                      'selected': selected,
                      'unselected': !selected,
                    },
                  ]"
                >
                  <b-form-checkbox
                    class="d-none pr-1"
                  ></b-form-checkbox>
                  <img
                    src="../assets/Images/checked.svg"
                    v-if="selected"
                    alt="checked"
                  />
                  <img
                    src="../assets/Images/unchecked.svg"
                    v-if="!selected"
                    alt="checked"
                  />
                  <span class="option pl-2 text-white">{{ option }}</span>
                  <span
                    :class="[
                      'position-absolute option-number font-weight-bold',
                      {
                        black: selected,
                        gray: !selected,
                      },
                    ]"
                    >{{ index + 1 }}</span
                  >
                </div>
              </label>
            </b-col>
            <b-col lg="4" xl="4" md="12" sm="12" xs="12" class="d-flex align-items-center" align-h="stretch">
                <div class="w-100">
                    <div class="d-flex my-1 justify-content-around align-items-center">
                    <img src="../assets/Images/all_pass_prices.svg" width="40px" height="40px" alt="all_pass_prices">
                    <span v-if="isVIP" class="color-pink"><b>VIP</b> 수강 신청</span>
                </div>
              <b-button
              :disabled="true"
              class="select_class w-100 d-flex justify-content-between p-0 align-items-center"
            >
              <img
                src="../assets/Images/calendar-silhouette.svg"
                width="20px"
                height="20px"
                alt="calendar-silhouette"
                class="ml-5"
              />
              <div class="mr-5" v-if="isVIP">
                <span class="d-block">{{ classInfo.teacherName }}</span>
                <span class="d-block">{{ classInfo.classTime }}</span>
              </div>
            </b-button>
                </div>
            </b-col>
            <b-col>
              <div class="bg-white w-75 m-auto border-radius px-3">
                  <span class="info"> 수업기간 : {{classInfo.programDesc}}</span>
              </div>
              <div class="bg-white w-75 mx-auto border-radius my-3 p-3">
                  <span class="d-block font-weight-bold py-3">결제금액</span>
                  <span class="d-block font-weight-bold color-pink h3">￦ {{price}}</span>
              </div>
            </b-col>
          </b-row>
           <div class="m-auto p-5 register position-absolute" v-if="!options">
             <div class="w-50 m-auto">
               <b-row no-gutters class="my-2">
               <span class="info">등록된 수강정보가 없습니다.</span>
             </b-row>
          <b-button @click="$router.push('/subscription')" class="w-100 py-3 px-5 cancel-btn register-1 d-flex align-items-center justify-content-around text-dark">
               <span class="font-weight-bold d-block text-left">지금 수강 등록하고 Mr.Bro의 멋진 영어회화 수업 즐기로 가기 </span>
            <b-col>
            <img class="arrow d-block z-index-front position-relative " src="../assets/newimage/arrow_back.svg" alt="arrow">
            </b-col>
          </b-button>
            <!-- <b-row>
              <span class="canceled">CANCELED CLASS</span>
            </b-row> -->
             </div>
           </div>
        </div>
        <div class="mt-3">
        <b-button @click="$emit('close')"
        v-if="options"
            class="custom-button m-md-auto  px-3 d-flex justify-content-around align-items-center" >
            <span class="d-block text-white" @click="$router.push('/')">홈으로 돌아가기</span>
            <b-icon icon="arrow-right"></b-icon>
          </b-button>
          <b-button @click="$router.push('/')"
          v-if="!options"
            class="custom-button m-md-auto  px-3 d-flex justify-content-around align-items-center" >
            <span class="d-block text-white">Go to home</span>
          </b-button>
          </div>
      </b-col>
    </b-row>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from '../layouts/pageLayout';
import { mapGetters, mapMutations, mapActions } from "vuex";
import {disablePaymentConfig} from "../constants/config";
import * as _ from "lodash";
export default {
  name: "ClassDetails",
  components: {
    PageLayout
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      tokens: "tokens",
      classTypes: "classTypes",
      enrollmentData: "enrollmentData",
    }),
    grade: function () {
      console.log(this.enrollmentData)
      return this.enrollmentData && this.enrollmentData.grade === "VIP"
        ? "VIP"
        : "";
    },
    isVIP: function () {
      return this.enrollmentData && this.enrollmentData.grade === "VIP"
        ? true
        : false;
    },
    price: function () {
      return this.enrollmentData && this.enrollmentData.price
        ? this.enrollmentData.price
        : 0;
    },
    userName: function () {
      return this.currentUser ? this.currentUser.name : "";
    },
    profilePicUrl: function () {
      return this.currentUser && this.currentUser.profilePicUrl
        ? this.currentUser.profilePicUrl
        : "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png";
    },
    options: function() {
      if (this.enrollmentData && this.enrollmentData.classType) {
        const classTypeArr = this.enrollmentData.classType.map(
          (item) => item.name
        );
        if (classTypeArr && classTypeArr.length > 0) {
          return classTypeArr;
        } else {
          return [];
        }
      }
    },
    classInfo: function () {
      return {
        classTime: _.get(this.enrollmentData, ['class', '0' ,'classTime']),
        programDesc: _.get(this.enrollmentData, ['classType', '0' ,'program', 'desc']),
        teacherName: _.get(this.enrollmentData, ['class', '0' ,'teacher', 'name'])
      }
    },
    classTypeString: function () {
      if (this.enrollmentData && this.enrollmentData.classType) {
        const classTypeArr = this.enrollmentData.classType.map(
          (item) => item.name
        );
        if (classTypeArr && classTypeArr.length > 0) {
          return classTypeArr.join(" / ");
        } else {
          return "";
        }
      }
    },
  },
  data() {
      return {
          selected: true,
      //     options: [
      //   "일상회화 수업",
      //   "초급회화 수업",
      //   "중고급 수업",
      //   "비즈니스 수업",
      // ],
      disablePaymentConfig: disablePaymentConfig
      }
  },
  methods: {
    openCancelPayment() {
        this.$router.push('/cancel_confirmation')
    //   this.$emit('close');
    //   this.$emit('confirm');
    }
  }
};
</script>

<style scoped>
.bg-black {
  background: #000000;
}
.content {
  background: #fff5ad;
  border-radius: 10px;
}
.custom-check-box {
  border-radius: 25px;
}

.cursor_pointer {
  cursor: pointer;
}
.option-number {
    padding-left: 0.8em;
    top: -1em;
    right: 2em;
    transform: scale(3);
}
.unselected {
  background: #d8d8d8;
}

.selected {
  background: #ffe63a;
}
.select_class {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 60px;
  background: white;
  border: none;
  color: black;
  min-height: 60px;
}
.color-pink {
  color: #ff2a58;
}

.custom-button {
  background: #ff2a58;
  border-radius: 25px;
  border: none;
}
.border-radius {
    border-radius: 8px;
}
.closebutton{
    background-image: url('../assets/Images/closebutton circle.png');
    margin-left: 1022px;
    padding: 0px 0px 2px 5px;
}
.coursecancel-wrapper{
    border: 1px solid #232222;
    background: #232222;
border-radius: 25px;
color: #615E5E;
}
.info {
  color: #FF2A58;
}

.coursecancel-wrapper:hover{
    border: 1px solid #FF9A9A;
    background: #FF9A9A;
    color: #FF2A58;
}

@media (max-width: 600px){
  .modal-body {
    padding: 0px;
  }
  .info {
    font-size: 10px;
  }
  .option {
  font-size: 10px;
}
} 
 @media (min-width: 601px){
  .modal-body {
    padding: 16px;
  }
  .info {
    font-size: 14px;
  }
  .option {
  font-size: 14px;
}
}

.close-icon {
  position:absolute;
  font-size:14px;
  top: 0.5em;
  right: 0.5em;
}
.cancel{
  font-size: 14px;
}
.arrow{
  width: 80px;
}
.cancel-btn{
  border-radius: 3%;
  border: none;
  outline: none;
}
.register{
  background: rgba(196, 196, 196, 0.9);
  height: 97%;
  width: 98%;
  display: grid;
  place-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.register-1{
  background-color:  #FCE022;;
}
.canceled{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 35px;
color: #FF2A58;
margin-top: 15px;
margin-left: 15px;
}
</style>